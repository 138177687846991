
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "2e2ccbd0-7bc7-46d2-b246-71c885ded495"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
